import React from "react";

const LogoImage = (props: any) => {
  const { height, clsName, width } = props;
  let logo = require("../../../Assests/Images/sg_logo.jpg");
  return (
    <>
      <img
        src={logo}
        style={{ height: height, width: width }}
        className={clsName}
        alt="sglogo"
      />
    </>
  );
};

export default LogoImage;
